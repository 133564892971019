/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import Text from "../../components/content/Text";
import style from "../../assets/scss/view/newAction.module.scss"
import React, { useEffect, useRef, useState } from "react";
import TextArea from "../../components/form/TextArea";
import Button from "../../components/content/Button";
import UploadFile from "../../components/global/UploadFile";
import Load from "../../components/global/Load";
import Select from "../../components/form/Select";
import { Controller, useForm,} from "react-hook-form";
import ChartList from "../../components/global/ChartList";
import arrowDown from '@ingka/ssr-icon/paths/arrow-down-to-line'
import { useDispatch, useSelector } from "react-redux";
import ConvertToOption from "../../utils/convertToOption";
import { getFlowDetails } from "../../services/newActionServices";
import { getCategoryProductivity } from "../../services/mainServices";
import { setCategoryProductivity } from "../../store/filtersSlice";
import { updateFilterCategoryProductivity, updateFilterDepartment } from "../../store/globalSlice";
import RequiredField from "../form/RequiredField";
import useDateOptions from "../../utils/useDateOptions";

export default function ProductivityNewAction({flow, user, employees, store, departmentSelected, categorySelected, saveAction, loadingButton}) {
  const [t] = useTranslation("global")
  const [loading2, setLoading2] = useState(true)
  const [variableSelected, setVariableSelected] = useState(null)
  const [start, setStart] = useState()
  const [end, setEnd] = useState()
  const [responsible, setResponsible] = useState()
  const [contributor, setContributor] = useState()
  const [DL, setDL] = useState()
  const [description, setDescription] = useState("")
  const [files, setFiles] = useState([])
  const qualityVarRef = useRef()
  const formRef = useRef()
  const [buttonSubmitActive, setButtonSubmitActive] = useState(false)
  const departments = useSelector((state) => state.filters.departments)
  const categories = useSelector((state) => state.filters.categoryProductivity)
  const [department, setDepartment] = useState(ConvertToOption(departmentSelected))
  const [category, setCategory] = useState(ConvertToOption(categorySelected))
  const [variables, setVariables] = useState(null)
  const year = useSelector((state) => state.global.lastDateData.year)
  const { handleSubmit, control } = useForm()
  const dispatch = useDispatch();
  const {optionsWeekStart, optionsWeekEnd, optionsMonthStart, optionsMonthEnd} = useDateOptions()

  const goToGetVariables = () => {
    setLoading2(true)
    const params = {
      flow: flow.id,
      hfb: "",
      store: store.id,
      pa: "",
      orderby: "",
      ascending: 0,
      n_acciones: "",
      department: department.value,
      productivityCategory: category.value
    }
    getFlowDetails(params).then( details => {
      setVariables(details.data)
      if(details.data.length > 0){
        setTimeout(()=>qualityVarRef.current.scrollIntoView(
          { 
            behavior: 'smooth', 
            block: 'start' 
          }), 100)
      }
    }).finally(() => setLoading2(false))
  }

  useEffect(() => {
    if(department && category){
      setVariableSelected(null)
      goToGetVariables()
    }
  }, [department, category, store])

  const handleFiles = (files)=>{
    setFiles(files)
  }

  useEffect(()=>{
    if(variableSelected && responsible && contributor && start && end !== undefined && description) setButtonSubmitActive(true)
  },[variableSelected,responsible,contributor,start,end, description])

  const goToSaveAction = ()=>{
    const item = {...variableSelected}
    delete item.chart
    const data = {
      flowid: flow.id,
      item: [item],
      actionname: variableSelected.name,
      department: department.value,
      categoryProductivity: category.value,
      actionresponsible: responsible,
      actioncontributor: contributor,
      actiondl: DL,
      actiondescription: description,
      storeid: store.id,
      statusact: "registro",
      actionregisteruserid: user,
      wkstartdate: variableSelected.temporality === "weekly" ? start : '',
      wkenddate: variableSelected.temporality === "weekly" ? end : '',
      mthStartDate: variableSelected.temporality === "monthly" ? start : '',
      mthEndDate: variableSelected.temporality === "monthly" ? end : '',
      files: files,
      year: year,
      ...(variableSelected.temporality === "weekly" ? 
        {wkVariableValue: variableSelected.wkVariableValue} : 
        {mthVariableValue: variableSelected.mthVariableValue}
      )
    }
    saveAction(data)
  }

  const goToGetCategories = (department) => {
    setCategory(null)
    setVariables(null)
    setVariableSelected(null)
    setStart(null)
    setEnd(null)
    dispatch(updateFilterDepartment(department))
    getCategoryProductivity({department: department}).then( cats => {
      dispatch(setCategoryProductivity(cats))
      dispatch(updateFilterCategoryProductivity(cats[0]))
    })
  }

  const dateValue = (value) => {
    const options = variableSelected.temporality === "weekly" ? optionsWeekEnd : optionsMonthEnd
    return options.find((o) => o.value === value)
  }

  return (
    <>
      <div className={style.container}>
        <Text 
          tagName="h2" 
          headingSize="m" 
          className={style.stepTitle}>
            <span>1</span>
            {t("new-action.stepOneProductivity")}
        </Text>
        
        <div className="row" style={!department ? {marginBottom: "9rem"} : {marginBottom: "0"}}>
          <Select
            formField={{className:"col-12"}}
            label={t('global.department')}
            value={department}
            onChange={(val) => {
              setDepartment(val)
              goToGetCategories(val.value)
            }}
            id="department"
            options={ConvertToOption(departments)}
          />
          <Select
            formField={{className:"col-12"}}
            label={t("global.category")}
            onChange={(val) => {
              setCategory(val)
            }}
            value={category}
            id="category"
            options={ConvertToOption(categories)}
          />
        </div>
        
        {loading2 &&
          <Load />
        }
        
        {!loading2 && variables?.length > 0 &&
          <>
          <div ref={qualityVarRef} className="pt-3 row">
            <div className="col-12">
              <ChartList data={variables} updateSelectedItem={(item) => setVariableSelected(item)}/>
            </div>
          </div>
          {variableSelected && 
            <>
              <div ref={formRef}></div>
              <Button 
                type="emphasised" 
                iconOnly 
                size="small" 
                ssrIcon={arrowDown} 
                className={style.buttonDown}
                onClick={() => setTimeout(()=>formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }), 100)}
              />
            </>
            }
          </>
        }
        {!loading2 && variables?.length === 0 &&
        <Text 
          tagName="p" 
          bodySizeSize="m" 
          className="text-center text-red">
            {t('new-action.noVariables')} 
        </Text>
        }
        
          {/* STEP TWO */}
        {variableSelected &&
          <form onSubmit={handleSubmit(goToSaveAction)} >
            <Text 
              tagName="h2" 
              headingSize="m" 
              className={style.stepTitle}>
                <span>2</span>
                {t("new-action.stepTwo")}: {variableSelected.name}  
            </Text>
            <div className="row">
              <Controller
                control={control}
                name="startDate"
                rules={{required: true }}
                render={({ field:{value, onChange, ref}, fieldState: {error}}) => (
                <Select
                  formField={{
                    className:"col-6",
                    shouldValidate: error !== undefined,
                    validation:{
                      id:"error-msg-startDate",
                      msg:t("validation.required"),
                      type: "error"
                    }
                  }}
                  label={<RequiredField text={t("new-action.start")}/>}
                  value={dateValue(value)}
                  onChange={val => {
                    onChange(val.value)
                    setStart(val.value)
                  }}
                  inputRef={ref}
                  options={variableSelected.temporality === "weekly" ? optionsWeekStart : optionsMonthStart}
                  id="startDate"
                />
                )} 
              />

              <Controller
                control={control}
                name="endDate"
                rules={{required: true }}
                render={({ field:{value, onChange, ref}, fieldState: {error}}) => (
                <Select
                  formField={{
                    className:"col-6",
                    shouldValidate: error !== undefined,
                    validation:{
                      id:"error-msg-endDate",
                      msg:t("validation.required"),
                      type: "error"
                    }
                  }}
                  label={<RequiredField text={t("new-action.end")}/>}
                  value={dateValue(value)}
                  onChange={val => {
                    onChange(val.value)
                    setEnd(val.value)
                  }}
                  inputRef={ref}
                  options={variableSelected.temporality === "weekly" ? optionsWeekEnd : optionsMonthEnd}
                  id="endDate"
                />
                )} 
              />

              <Controller
                control={control}
                name="responsible"
                rules={{required: true }}
                render={({ field:{value, onChange, ref}, fieldState: {error}}) => (
                <Select
                  formField={{
                    className:"col-12",
                    shouldValidate: error !== undefined,
                    validation:{
                      id:"error-msg-responsible",
                      msg:t("validation.required"),
                      type: "error"
                    }
                  }}
                  label={<RequiredField text={t("new-action.responsible")}/>}
                  value={employees.find((c) => c.value === value)}
                  onChange={val => {
                    setResponsible(val)
                    onChange(val.value)
                  }}
                  inputRef={ref}
                  options={employees}
                  isSearchable={true}
                  id="responsible"
                />
                )} 
              />

              <Controller
                control={control}
                name="contributor"
                rules={{required: true }}
                render={({ field:{value, onChange, ref}, fieldState: {error}}) => (
                <Select
                  formField={{
                    className:"col-12",
                    shouldValidate: error !== undefined,
                    validation:{
                      id:"error-msg-contributor",
                      msg:t("validation.required"),
                      type: "error"
                    }
                  }}
                  label={<RequiredField text={t("new-action.contributor")}/>}
                  value={employees.find((c) => c.value === value)}
                  onChange={val => {
                    onChange(val.value)
                    setContributor(val)
                  }}
                  inputRef={ref}
                  options={employees}
                  isSearchable={true}
                  id="contributor"
                />
                )} 
              />
              <Controller
                control={control}
                name="DL"
                rules={{required: false }}
                render={({ field:{value, onChange, ref}, fieldState: {error}}) => (
                <Select
                  formField={{
                    className:"col-12",
                    shouldValidate: error !== undefined,
                    validation:{
                      id:"error-msg-contributor",
                      msg:t("validation.required"),
                      type: "error"
                    }
                  }}
                  label={t("new-action.addDL")}
                  value={employees.find((c) => c.value === value)}
                  onChange={val => {
                    onChange(val.value)
                    setDL(val)
                  }}
                  inputRef={ref}
                  options={employees}
                  isSearchable={true}
                  id="DL"
                />
                )} 
              />
              <Controller
                control={control}
                name="description"
                rules={{maxLength: 1000, required: true }}
                render={({ field:{ onChange, ref}, fieldState: {error}}) => (
                  <TextArea
                    formField={{
                      className:"col-12",
                      characterLimit:1000,
                      shouldValidate: error !== undefined,
                        validation:{
                          id:"error-msg-description",
                          msg:t("validation.maxLength"),
                          type: "error"
                        }
                    }}
                    textArea={{
                      label: <RequiredField text={t("new-action.description")}/>,
                      value: description,
                      onChange: e => {
                        onChange(e.target.value)
                        setDescription(e.target.value)
                      },
                    }}
                    inputRef={ref}
                  />
                )} 
              />
              <UploadFile handleFiles={handleFiles}/>
              <Button
                className={!buttonSubmitActive ? style.submitButton : 'col-12'}
                type="emphasised"
                text={t("new-action.save")}
                htmlType="submit"
                loading={loadingButton}
              />
            </div>
          </form>
        }
      </div>
    </>
  )
}
