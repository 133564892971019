/* eslint-disable react-hooks/exhaustive-deps */
import "../assets/scss/view/login.scss";
import Text from "../components/content/Text";
import { useTranslation } from "react-i18next";
import Button from "../components/content/Button";
import { useDispatch, useSelector } from "react-redux";
import { changeLang, doLogin, doLoginFinalize, doLogout, doToken, updateActualDate, updateFilterCategoryProductivity, updateFilterDepartment, updateFilterMonth, updateFilterPeriod, updateFilterStore, updateLastDateData, updateMyStore, updateStoreSelected } from '../store/globalSlice';
import { useEffect, useState } from "react";
import Load from "../components/global/Load";
import { authSSO } from "../services/loginServices";
import { getCategoryProductivity, getDepartments, getHfbs, getPas, getPeriods, getStores } from "../services/mainServices";
import { setCategoryProductivity, setDepartments, setHfbs, setPas, setPeriods, setStores } from "../store/filtersSlice";
import { COORDINA, COWORKER, SUPERADMIN } from "../utils/roles";

export default function Login() {
  const [t] = useTranslation("global")
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const token = useSelector((state) => state.global.token)
  const user = useSelector((state) => state.global.user)
  const rol = useSelector((state) => state.global.rol)
  const [year, setYear] = useState("20**")
  const location = window.location.origin
  const [ready, setReady] = useState(0)
  const [storeId, setStoreId] = useState(null)
  
  const goToSSO = ()=>{
    window.location.href = "https://login.microsoftonline.com/720b637a-655a-40cf-816a-f22f40755c2c/oauth2/v2.0/authorize?client_id="+
      process.env.REACT_APP_MICROSOFT_CLIENT_ID+"&redirect_uri="+
      location+"/login&scope="+
      process.env.REACT_APP_MICROSOFT_SCOPE
      +"&response_type=code&response_mode=query"
  }

  useEffect(()=>{
    const date = new Date()
    setYear(date.getFullYear())

    const values = window.location.search
    //There is no token but there is a code in slug
    console.log("PARAMS", values)
    console.log("TOKEN", token)
    if((values && token === "undefined") || (values && token === null) ){
      console.log("HAY CODE Y NO HAY TOKEN")
      const urlParams = new URLSearchParams(values)
      const code = urlParams.get('code');
      if(code){
      console.log("CODE", code)
        setLoading(true)
        authSSO({
          code: code, 
          redirectUri: location+"/login",
          originApp: process.env.REACT_APP_ORIGIN_APP
        })
        .then((response)=>{
          console.log("RESPONSE", response)
          setStoreId(response.officeId)
          dispatch(doLogin(
            {
              user: response.mailNickName,
              userName: response.displayName,
              rol: checkRol(response),
              email: response.mail,
              myStore: {
                id:response.officeId, 
                name: response.officeName,
                officeLocation: response.officeLocation
              }
            }))
          dispatch(changeLang(response.preferredLanguage))
          dispatch(updateActualDate())
          dispatch(doToken(response.accessToken))
        })
      }
    }
  },[])

  const checkRol = (response) => {
    if(response.rolName === SUPERADMIN){
      return response.rolName
    }
    return response.position || COWORKER
  }

  useEffect(() => {
    if(token && user && loading && storeId && rol) {
      getMainData()
    }
  }, [token, user, storeId, rol])

  const getMainData = () => {
    getPeriods().then(periods => {
      dispatch(setPeriods(periods))
      dispatch(updateFilterPeriod(periods[1]))
      setReady(ready => ready + 1)
    }).catch(() => dispatch(doLogout()))

    getHfbs().then( hfbs => {
      dispatch(setHfbs(hfbs))
      setReady(ready => ready + 1)
      getPas({hfb:hfbs[0].id}).then( pas => {
        dispatch(setPas(pas))
        setReady(ready => ready + 1)
      })
    })

    getStores().then( stores => {
      dispatch(setStores(stores))
      const myStore =  stores.find(el => el.id === storeId)
      if(myStore){
        dispatch(updateStoreSelected(myStore))
        dispatch(updateFilterStore(myStore))
      }else{
        dispatch(updateMyStore(stores[0]))
        dispatch(updateStoreSelected(stores[0]))
        dispatch(updateFilterStore(stores[0]))
      }
      
      setReady(ready => ready + 1)
    }).catch(() => dispatch(doLogout()))

    getDepartments().then( departments => {
      dispatch(setDepartments(departments))
      dispatch(updateFilterDepartment(departments[0]))
      setReady(ready => ready + 1)
      getCategoryProductivity({department: departments[0]}).then( cats => {
        dispatch(setCategoryProductivity(cats))
        dispatch(updateFilterCategoryProductivity(cats[0]))
        setReady(ready => ready + 1)
      })
    }).catch(() => dispatch(doLogout()))
    
  }

  useEffect(() => {
    if(ready === 6){  
      dispatch(doLoginFinalize(true))
    }
  },[ready])

  return (
    <div className="login">
      <div className="container">
        <div className="brand">
          <Text
            tagName="h1"  
            className="title">
              <span>Im</span>
              <span>pulse</span>
          </Text>
          <Text
            tagName="h2" 
            headingSize="s"
            className="subtitle">
              {t("login.subtitle")}
          </Text>
        </div>
      </div>
      <div className="form">
          <div className="container">
            {!loading &&
            <div className="form-container">
              <Button
                type="emphasised"
                text={t("login.buttonContinue")}
                onClick={goToSSO}
              ></Button>
            </div>
            }
            {loading &&
            <div className="mt-3">
              <Load/>
            </div>
            }
          </div>
        </div>
        
        
        <div className="copy">
        <Text
            tagName="span" 
            bodySize="s">
              @Digital Spain 2021-{year}
          </Text>
          
        </div>
    </div>
  )
}